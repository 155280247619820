<script async setup lang="ts">
useSeoMeta({
  title: "TAX | Calculator de penalitate pentru întârzieri la plata facturilor",
  ogTitle: "TAX | Calculator de penalitate pentru întârzieri la plata facturilor",
  description: "Unelte și calculatoare esențiale pentru profesioniștii din domeniul juridic. Optimizează activitatea ta cu resursele noastre inteligente.",
  ogDescription: "Unelte și calculatoare esențiale pentru profesioniștii din domeniul juridic. Optimizează activitatea ta cu resursele noastre inteligente."
})
</script>

<template>
  <ULandingSection headline="Instrumente digitale pentru calcule financiare." title="Calculatoare">
    <UPageGrid>
      <ULink class="text-left" to="/penalty">
        <ULandingCard class="h-full" title="Penalitate"
          description="Calculeaza penalitatile pentru facturile neachitate la timp." icon="i-heroicons-archive-box" />
      </ULink>
      <ULink class="text-left" to="/interest">
        <ULandingCard class="h-full" title="Dobanda"
          description="Determinați dobânda aplicabilă pentru facturile plătite cu întârziere."
          icon="i-heroicons-presentation-chart-line" />
      </ULink>
      <ULink class="text-left blur" to="/currency" disabled>
        <ULandingCard class="h-full" title="Curs valutar"
          description="Converteste sume intre valute la cursul BNM sau cel dorit."
          icon=" i-heroicons-currency-dollar" />
      </ULink>
    </UPageGrid>
  </ULandingSection>
</template>
